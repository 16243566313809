import React, { useRef, useEffect } from 'react';
import './Decoration.css';

export default function Decoration() {
  const hrRef1 = useRef();
  let flip = false;
  let i = 10;

  useEffect(() => {
    const interval = setInterval(() => updateWidth(), 50);

    return () => clearInterval(interval);
  }, []);

  const updateWidth = () => {
    hrRef1.current.style.width = (i) + "%";
    if (flip === false)
      i++;
    else
      i--;

    if (i === 70 || i === 10)
      flip = !flip

  }

  return (
    <div className="Decoration">
      <hr ref={hrRef1} />
    </div>
  )
}
