import React, { useEffect, useRef } from 'react';
import { HiOutlinePaperAirplane } from 'react-icons/hi';
import useScrollPosition from "../../useScrollPosition";

import PropTypes from 'prop-types';
import './AboutUs.css';

export default function AboutUs() {
  const planeRef = useRef();
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    planeRef.current.className = planeRef.current.getBoundingClientRect().y < scrollPosition ? "plane-showed" : "plane";
  })

  return (
    <div className="aboutus">
      <div className="title">
        <h1>Why SODIT?</h1>
        <div className="planeContainer">
          <div ref={planeRef} className="plane" ><HiOutlinePaperAirplane /></div>
        </div>
      </div>
      <div className="content">
        <h2>Make your decision easier!</h2>
        <p>Save time and start working with SODIT to maximize your success. Time is valuable, so let's get started!</p>

        <p>At SODIT, we offer two options for customers looking to establish their own business websites. The first option allows you to utilize our pre-created templates to create a high-quality website. Our templates are powered by WordPress, the most popular content management system globally, offering highly customizable designs. By choosing WordPress, you can quickly and cost-effectively build a professional website.</p>

        <p>For those with ambitious goals to take their business to new heights, we provide complete website development services from scratch. Our team of experts leverages the latest web technologies to code and design your website. If you're interested in incorporating 3D elements into your website, this option is worth considering.</p>

        <p>Experience the power of our search engine optimization (SEO) services, connecting you directly with potential customers. With our guarantee of high visibility on Google's search engine, we ensure that your website stands out. Additionally, our SEO services include managing your business profile for increased exposure.</p>

        <p>We also excel in Facebook marketing, creating engaging content using tools like Canva or enhancing your photos with Photoshop. Let us assist you in setting realistic goals and budgets for boosting your Facebook posts and advertisements.</p>

        <p>Take advantage of our integration with a reliable 3rd party online ordering system, allowing you to receive online orders and table bookings effortlessly. Our satisfied customers have already embraced this stable service provided by Oracle, a world-leading IT company. We can seamlessly integrate the ordering system with your website or place an order button directly on your Facebook business page.</p>

        <p>Unlike many web design agencies, we don't rely on conventional hosting services. Instead, we build your web server on Amazon Web Services (AWS). As a leading cloud service provider with a branch in New Zealand, AWS ensures faster and more reliable websites running on its virtual servers. Rest assured, our technical capabilities make this possible.</p>
      </div>
    </div>
  )
}


AboutUs.propTypes = {};

AboutUs.defaultProps = {};

