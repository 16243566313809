import React, { useEffect, useRef } from 'react';
import useScrollPosition from "../../useScrollPosition";

import PropTypes from 'prop-types';
import './Portfolio.css';

export default function Portfolio() {
  const scrollPosition = useScrollPosition();
  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const imageRef3 = useRef();
  const imageRef4 = useRef();
  const imageRef5 = useRef();
  const imageRef6 = useRef();
  const imageRef7 = useRef();

  useEffect(() => {
    let temp = scrollPosition;
    imageRef1.current.className = imageRef1.current.getBoundingClientRect().y < 700 ? "img-showed" : "img";
    imageRef2.current.className = imageRef2.current.getBoundingClientRect().y < 700 ? "img-showed" : "img";
    imageRef3.current.className = imageRef3.current.getBoundingClientRect().y < 700 ? "img-showed" : "img";
    imageRef4.current.className = imageRef4.current.getBoundingClientRect().y < 700 ? "img-showed" : "img";
    imageRef5.current.className = imageRef5.current.getBoundingClientRect().y < 700 ? "img-showed" : "img";
    imageRef6.current.className = imageRef6.current.getBoundingClientRect().y < 700 ? "img-showed" : "img";
    imageRef7.current.className = imageRef6.current.getBoundingClientRect().y < 700 ? "img-showed" : "img";
  })
  return (
    <div className="portfolio">
      <div className="p-section-container1">
        <div className="p-section-container2">
          <div className="section-pf">
            <a href="https://map.taurangahanin.info/" target="_blank" rel="noreferrer">
              <img ref={imageRef1} className="img" src={process.env.PUBLIC_URL + "/Map.png"} alt="Tauranga Spots - Map" />
              <p className="p-pf">Tauranga Spots<br />Map</p>
            </a>
          </div>
          <div className="section-pf">
            <a href="https://www.restaurantlogin.com/ordering/restaurant/menu?company_uid=ae9e5561-d0ea-42a6-b73e-8b116a532fb1&restaurant_uid=66441560-db0f-43d9-8715-125eb2d0a656&facebook=true" target="_blank" rel="noreferrer">
              <img ref={imageRef5} className="img" src={process.env.PUBLIC_URL + "/OrderingSystem.webp"} alt="Starter Templates - Brainstorm Force" />
              <p className="p-pf">Online Ordering<br />Restaurant</p>
            </a>
          </div>
          <div className="section-pf">
            <a href="https://startertemplates.com/?page-builder=elementor" target="_blank" rel="noreferrer">
              <img ref={imageRef7} className="img" src={process.env.PUBLIC_URL + "/StarterTemplates.webp"} alt="Starter Templates - Brainstorm Force" />
              <p className="p-pf">Elementor<br />Templates</p>
            </a>
          </div>
        </div>
        <div className="p-section-container2">
          <div className="section-pf">
            <a href="https://meltingpotnz.com/" target="_blank" rel="noreferrer">
              <img ref={imageRef3} className="img" src={process.env.PUBLIC_URL + "/MultiBranchRestaurant.webp"} alt="Starter Templates - Brainstorm Force" />
              <p className="p-pf">Multi Branch<br />Restaurant</p>
            </a>
          </div>
          <div className="section-pf">
            <a href="https://sample1.sodit.cyou" target="_blank" rel="noreferrer">
              <img ref={imageRef4} className="img" src={process.env.PUBLIC_URL + "/OnlineFlyer.webp"} alt="Starter Templates - Brainstorm Force" />
              <p className="p-pf">Online Flyer<br />3D Web</p>
            </a>
          </div>
        </div>
        <div className="p-section-container3">
          <div className="section-pf">
            <a href="https://sample2.sodit.cyou" target="_blank" rel="noreferrer">
              <img ref={imageRef2} className="img" src={process.env.PUBLIC_URL + "/ArtGallery.webp"} alt="Simple 3D Art Gallery" />
              <p className="p-pf">Art Gallery<br />3D Web</p>
            </a>
          </div>
          <div className="section-pf">
            <a href="https://play.google.com/store/apps/details?id=club.allim.newsfeeds&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" rel="noreferrer">
              <img ref={imageRef6} className="img" src={process.env.PUBLIC_URL + "/NewsFeedsMobile.png"} alt="Starter Templates - Brainstorm Force" />
              <p className="p-pf">NZ News Feeds<br />Android App</p>
            </a>
          </div>
        </div>
      </div>
    </div >
  );
}




Portfolio.propTypes = {};

Portfolio.defaultProps = {};
