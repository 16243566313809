import './App.css';

import Navbar from './components/Navbar/Navbar'
import Ourservices from './components/OurServices/OurServices'
import Portfolio from './components/Portfolio/Portfolio'
import Aboutus from './components/AboutUs/AboutUs'
import Contact from './components/Contact/Contact'
import Decoration from './components/Decoration/Decoration'

import * as THREE from 'three'
import React, { useState, useRef, Suspense, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Points, PointMaterial } from '@react-three/drei'
import * as random from 'maath/random/dist/maath-random.esm'

export default function App() {  
  return (
    <>
      <Navbar />
      <div id="top">
        <Suspense>
          <Canvas camera={{ position: [0, 0, 10] }}>
            <Stars />
          </Canvas>
          <Overlay />
        </Suspense>
      </div>
      <div id="templates">
        <Portfolio />
      </div>
      <div id="decoration">
        <Decoration />
      </div>
      <div id="ourservices">
        <Ourservices />
      </div>
      <div id="aboutus">
        <Aboutus />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </>
  )
}

function Overlay() {
  const [fontReady, setFontReady] = useState(false);
  useEffect(() => {
    document.fonts.ready.then(()=>{
      setFontReady(true);
    })
  }, []);

  return fontReady && (
    <div className="front">
      <div className="textTop">Where imagination becomes reality</div>
      <div className="textMiddleContainer">
        <h1 className="textMiddle">SODIT</h1>
      </div>
      <div className="textBottom">Website & Mobile app</div>
    </div>
  )
}

function Stars(props) {
  const ref = useRef()
  const materialRef = useRef()
  const vec = new THREE.Vector3();
  const [sphere] = useState(() => random.inSphere(new Float32Array(5000), { radius: 1.5 }))
  const time = new THREE.Clock();
  let previousTime = 0, currentTime

  useFrame((state, delta) => {
    vec.set(0, 0, 0);
    state.camera.position.lerp(vec, 0.01)
    ref.current.rotation.x -= delta / 15
    ref.current.rotation.y -= delta / 25
    currentTime = time.getElapsedTime()
    if ((currentTime - previousTime) > 4) {
      materialRef.current.color = new THREE.Color().setHSL(Math.random(), 0.5, 0.5)
      previousTime = time.getElapsedTime()
    }
  })

  return (
    <>
      <group rotation={[0, 0, Math.PI / 4]}>
        <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
          <PointMaterial ref={materialRef} transparent size={0.01} sizeAttenuation={true} depthWrite={true} />
        </Points>
      </group>
    </>
  )
}