import React, { useState, useEffect } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';

import useScrollPosition from "../../useScrollPosition";

import PropTypes from 'prop-types';
import './Navbar.css';

export default function Navbar() {
  const scrollPosition = useScrollPosition();
  const [isNavExpanded, setNavexpanded] = useState(false);
  const [isBackgroundBlack, setBackgroundBlack] = useState(false);
  let previousScrollPosition = 0;

  useEffect(() => {
    setNavexpanded(previousScrollPosition !== scrollPosition ? false : false)
    setBackgroundBlack(scrollPosition > window.innerHeight - 80 ? true : false)
  }, [previousScrollPosition, scrollPosition]);

  function updateNavExpanded() {
    setNavexpanded(isNavExpanded === true ? false : true)
  }

  return (
    <nav className={isBackgroundBlack ? 'navigation background-black' : 'navigation background-transparent'} >
      <a className={isBackgroundBlack ? 'brand' : 'brand hidden'} href="#top" >SODIT</a>
      <button className="hamburger" onClick={updateNavExpanded}>
        <GiHamburgerMenu />
      </button>
      <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
        <ul>
          <li>
            <a href="#top">TOP</a>
          </li>
          <li>
            <a href="#templates">CAPABILITIES</a>
          </li>
          <li>
            <a href="#ourservices">SERVICES</a>
          </li>
          <li>
            <a href="#aboutus">ABOUT US</a>
          </li>
          <li>
            <a href="#contact">CONTACT</a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  isNavExpanded: PropTypes.bool,
  isBackgroundBlack: PropTypes.bool
};