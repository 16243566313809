import React, { useEffect, useRef } from 'react';
import { FcAbout } from 'react-icons/fc';
import { FcSmartphoneTablet } from 'react-icons/fc';
import { FcAdvertising } from 'react-icons/fc';
import { FcVoicePresentation } from 'react-icons/fc';
import { FcSettings } from 'react-icons/fc';

import useScrollPosition from "../../useScrollPosition";

import PropTypes from 'prop-types';
import './OurServices.css';

export default function OurServices() {
  const scrollPosition = useScrollPosition();
  const ourServiceRef = useRef();
  const iconRef1 = useRef();
  const iconRef2 = useRef();
  const iconRef3 = useRef();
  const iconRef4 = useRef();
  const iconRef5 = useRef();

  useEffect(() => {
    iconRef1.current.className = iconRef1.current.getBoundingClientRect().y < scrollPosition ? "illustration-showed" : "illustration";
    iconRef2.current.className = iconRef2.current.getBoundingClientRect().y < scrollPosition ? "illustration-showed" : "illustration";
    iconRef3.current.className = iconRef3.current.getBoundingClientRect().y < scrollPosition ? "illustration-showed" : "illustration";
    iconRef4.current.className = iconRef4.current.getBoundingClientRect().y < scrollPosition ? "illustration-showed" : "illustration";
    iconRef5.current.className = iconRef5.current.getBoundingClientRect().y < scrollPosition ? "illustration-showed" : "illustration";
    ourServiceRef.current.className = ourServiceRef.current.offsetTop - 600 < scrollPosition ? "ourservices-showed" : "ourservices"
  })

  return (
    <div ref={ourServiceRef} className="ourservices">
      <div className="section-container1">
        <div className="section-container2">
          <div className="no-section">
            <h2>What will you get?</h2>
            <p>Stimulate your business growth with SODIT.
              Our customers see the improvement now.</p>
            <p>SODIT is here to support your business. Start working with us for marketing, automation and cost saving.</p>
          </div>
          <div className="section-os">
            <div ref={iconRef1} className="illustration"><FcSettings /></div>
            <h2>Hosting</h2>
            <h3>Bring your own website. We will host it on our server running on a world leading cloud service provider.</h3>
            <ol>
              <li>Stable</li>
              <li>Secure</li>
              <li>Low cost starts only from $15 per month</li>
              <li>Even free if you make maintenance contract with us</li>
            </ol>
          </div>
        </div>
        <div className="section-container2">
          <div className="section-os">
            <div ref={iconRef2} className="illustration"><FcSmartphoneTablet /></div>
            <h2>Android & ios development</h2>
            <h3>Utilize your smartphone for your business.</h3>
            <ol>
              <li>Cross Platform mobile development framework</li>
              <li>Native app development</li>
            </ol>
          </div>
          <div className="section-os">
            <div ref={iconRef3} className="illustration"><FcAdvertising /></div>
            <h2>Online<br />marketing</h2>
            <h3>Improve your marketing and meet your potential customers.</h3>
            <ol>
              <li>Google Search Engine</li>
              <li>Facebook</li>
              <li>Instagram</li>
            </ol>
          </div>
        </div>
        <div className="section-container3">
          <div className="section-os">
            <div ref={iconRef4} className="illustration"><FcAbout /></div>
            <h2>Web design & development</h2>
            <h3>Build your website to meet your business demands.</h3>
            <ol>
              <li>Wordpress website</li>
              <ul>
                <li>Swift website creation</li>
                <li>Low price starts only from $700</li>
              </ul>
              <li>Javascript website</li>
              <ul>
                <li>Highly customizable</li>
                <li>High quality with 3D canvas</li>
              </ul>
            </ol>
          </div>
          <div className="section-os">
            <div ref={iconRef5} className="illustration"><FcVoicePresentation /></div>
            <h2>IT consulting</h2>
            <h3>Work with us to solve IT problems and grow your business with reasonable cost.</h3>
            <ol>
              <li>Customer relationship management</li>
              <li>Various tools for saving your time and money</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

OurServices.propTypes = {
  ref1: PropTypes.element,
  ref2: PropTypes.element,
  ref3: PropTypes.element,
  ref4: PropTypes.element,
  ref5: PropTypes.element
};