import React, { Component } from 'react';
import { GrInstagram } from 'react-icons/gr';
import { GrFacebook } from 'react-icons/gr';
import PropTypes from 'prop-types';
import './Contact.css';

export default class Contact extends React.Component {

  render() {
    return (
      <div className="footer-container">
        <div className="footer-brand">
            <h1>SODIT</h1>
            <h4>© 2024. All right reserved.</h4>
        </div>
        <div className="footer-navigation">
          <h2>Navigation</h2>
          <ul>
            <li>
              <a href="#top">TOP</a>
            </li>
            <li>
              <a href="#ourservices">OUR SERVICES</a>
            </li>
            <li>
              <a href="#aboutus">ABOUT US</a>
            </li>
            <li>
              <a href="#contact">CONTACT</a>
            </li>
          </ul>
        </div>
        <div className="footer-contact">
          <h2>Contact</h2>
          <p>
            Mobile : <a href="tel:64221095607">(64)221095607</a>
          </p>
          <p>
            Email : customer@sodit.cyou
          </p>
          <span className="sns-icons">
            <a href="https://www.instagram.com/soditnz"><GrInstagram /></a>
            <a href="https://www.facebook.com/soditnz"><GrFacebook /></a>
          </span>
        </div>
        {/*
        <div className="footer-legal">
          <h2>Legal</h2>
        </div>
    */}
      </div>
    )
  }
}

Contact.propTypes = {};

Contact.defaultProps = {};